import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import {Alert, Button, Col} from "antd";
import {ErrorBoundary} from "react-error-boundary";
import Routes from "./routes/Routes";

type ErrorFallbackProps = {
    error: Error;
    resetErrorBoundary: () => void;
};

function ErrorFallback({error, resetErrorBoundary}: ErrorFallbackProps) {
    return (
        <Col>
            <div role='alert'>
                <Alert type='error' message='Un problème est survenu :'/>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Réessayer</Button>
            </div>
        </Col>
    );
}

function App() {

    return (
        // @ts-ignore
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
                window.location.reload();
            }}
        >
            <Routes/>
        </ErrorBoundary>

    );
}


export default App;
