import React, {lazy, Suspense} from "react";
import {Spin} from "antd";
import {useGlobalDataContext} from "../context/GlobalDataProvider";
import {Navigate, Route, Routes as ReactRoutes, useLocation} from "react-router-dom";

export const HomePath = '/';
export const LoginPath = '/login';

export const PlansPath = '/plans';
export const PlanCreatePath = '/plans/create';
export const PlanPatchPath = (id: number) => `/plans/modify/${id}`

export const PlansTagsPath = '/plans/tags';
export const PlanTagCreatePath = '/plans/tags/create';

export const CustomersPath = '/customers'
export const CustomerDetailsPath = (id: number) => `/customers/${id}/details`

export const SubscriptionsPath = '/subscriptions'

export const TokensPath = '/tokens'
export const TokensCreatePath = '/tokens/create'

export const ReleasesPath = '/releases'
export const ReleasesCreatePath = '/releases/create'
export const ReleasesPatchPath = (id: number) => `/releases/${id}/patch`

const HomePage = lazy(() => import('../pages/Home'));
const LoginPage = lazy(() => import('../pages/Login'));
const PlansPage = lazy(() => import('../pages/plan/Plans'));
const PlanCreatePage = lazy(() => import('../pages/plan/PlanCreate'));
const PlanPatchPage = lazy(() => import('../pages/plan/PlanPatch'));
const PlansTagsPage = lazy(() => import('../pages/planTag/PlansTag'));
const PlansTagCreatePage = lazy(() => import('../pages/planTag/PlanTagCreate'));
const CustomersPage = lazy(() => import('../pages/customer/Customers'));
const CustomerDetailsPage = lazy(() => import('../pages/customer/CustomerDetails'));
const SubscriptionsPage = lazy(() => import('../pages/Subscription'));
const TokensPage = lazy(() => import('../pages/token/Tokens'));
const TokenCreatePage = lazy(() => import('../pages/token/TokenCreate'));
const ReleasePage = lazy(() => import('../pages/release/Releases'));
const ReleaseCreatePage = lazy(() => import('../pages/release/ReleaseCreate'));
const ReleasePatchPage = lazy(() => import('../pages/release/ReleasePatch'));

const Routes = () => {
    return (
        <Suspense fallback={<Spin size='large'/>}>
            <ReactRoutes>
                <Route path={LoginPath} element={<LoginPage/>}/>
                <Route path={HomePath} element={<RequireAuth><HomePage/></RequireAuth>}/>
                <Route path={SubscriptionsPath} element={<RequireAuth><SubscriptionsPage/></RequireAuth>}/>
                <Route path={PlanCreatePath} element={<RequireAuth><PlanCreatePage/></RequireAuth>}/>
                <Route path={PlansPath} element={<RequireAuth><PlansPage/></RequireAuth>}/>
                <Route path={"/plans/modify/:planId"} element={<RequireAuth><PlanPatchPage/></RequireAuth>}/>
                <Route path={PlansTagsPath} element={<RequireAuth><PlansTagsPage/></RequireAuth>}/>
                <Route path={PlanTagCreatePath} element={<RequireAuth><PlansTagCreatePage/></RequireAuth>}/>
                <Route path={CustomersPath} element={<RequireAuth><CustomersPage/></RequireAuth>}/>
                <Route path={"/customers/:id/details"} element={<RequireAuth><CustomerDetailsPage/></RequireAuth>}/>
                <Route path={TokensPath} element={<RequireAuth><TokensPage/></RequireAuth>}/>
                <Route path={TokensCreatePath} element={<RequireAuth><TokenCreatePage/></RequireAuth>}/>
                <Route path={ReleasesPath}  element={<RequireAuth><ReleasePage/></RequireAuth>}/>
                <Route path={ReleasesCreatePath} element={<RequireAuth><ReleaseCreatePage/></RequireAuth>}/>
                <Route path={"/releases/:id/patch"} element={<RequireAuth><ReleasePatchPage/></RequireAuth>}/>
            </ReactRoutes>
        </Suspense>
    )
}

function RequireAuth({children}: { children: JSX.Element }) {
    let {isAuthenticated} = useGlobalDataContext();
    let location = useLocation();
    return isAuthenticated ? children : <Navigate to={LoginPath} state={{from: location}} replace/>
}

export default Routes