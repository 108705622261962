import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {getToken, removeToken, setToken} from "../utils/utils";
import {HomePath} from "../routes/Routes";
import {useNavigate} from "react-router-dom";
import {setAuthorization} from "../api/axiosConfig";

const useGlobalData = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

    const navigate = useNavigate();
    useEffect(() => {
        let token = getToken();
        if (token) {
            setAuthorization(token);
            setIsAuthenticated(true)
            navigate(HomePath)
        } else {
            setIsAuthenticated(false)
        }
    }, [])

    return {
        getToken,
        setToken,
        removeToken,
        isAuthenticated,
        setIsAuthenticated
    }
}

type UseGlobalDataType = ReturnType<typeof useGlobalData>;

const GlobalDataContext = createContext<UseGlobalDataType | undefined>(undefined);

export const useGlobalDataContext = () => useContext(GlobalDataContext)!;

export const GlobalDataProvider = ({children}: { children: ReactNode }) => (
    <GlobalDataContext.Provider value={useGlobalData()}>
        {children}
    </GlobalDataContext.Provider>
);